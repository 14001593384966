import React from 'react'
import MainLayout from '../layouts/MainLayout'
import MaintenanceView from '../views/MaintenanceView'

const MaintenancePage = () => {
  return (
    <MainLayout className="maintenance">
      <MaintenanceView />
    </MainLayout>
  )
}

export default MaintenancePage
