import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import MaintenacePage from '../ui/pages/MaintenancePage'

const Maintenace = props => (
  <>
    <HelmetComponent
      title={metaTags.maintenanceTitle}
      description={metaTags.maintenance}
      page="maintenance"
    />
    <AppWrapper {...props}>
      <MaintenacePage />
    </AppWrapper>
  </>
)

export default Maintenace
