import React from 'react'
import AudienceView from './AudienceView'
import smImage from '../../assets/images/hero-maintenance-sm.png'
import mdImage from '../../assets/images/hero-maintenance-md.png'
import lgImage from '../../assets/images/hero-maintenance.png'

const maintenanceItems = [
  { text: 'key-benefits:maintenance:item1' },
  { text: 'key-benefits:maintenance:item2' },
  { text: 'key-benefits:maintenance:item3' },
  { text: 'key-benefits:maintenance:item4' },
  { text: 'key-benefits:maintenance:item5' },
  { text: 'key-benefits:maintenance:item6' },
]

const MaintenanceView = () => {
  return (
    <>
      <AudienceView
        page="maintenance"
        title="maintenance:title"
        subtitle="noun:maintenance-servicing"
        buttonText="action:see-case-study"
        smImage={smImage}
        mdImage={mdImage}
        lgImage={lgImage}
        videoTitle="Aucta training video"
        videoUrl="https://www.youtube.com/embed/CVbvtNe5UR4"
        items={maintenanceItems}
        buttonTo="/eos"
      />
    </>
  )
}

export default MaintenanceView
